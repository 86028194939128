<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea">
        <h3>
          <b>Filter Surat</b>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <form method="post" @submit.prevent="filterData">
              <div class="form-body">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        Periode Surat
                      </label>
                      <div class="col-md-3">
                        <input
                          type="date"
                          class="form-control"
                          data-date=""
                          data-date-format="YYYYY-MM-DD"
                          v-model="start_date"
                        />
                      </div>
                      <label
                        :class="[
                          'control-label',
                          { 'text-center': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        s/d
                      </label>
                      <div class="col-md-3">
                        <input
                          type="date"
                          class="form-control"
                          data-date=""
                          data-date-format="YYYYY-MM-DD"
                          v-model="end_date"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        Nama Berkas
                      </label>
                      <div class="col-md-8">
                        <Input
                          v-model="payload.namafolder"
                          type="text"
                          name="namafolder"
                          id="namafolder"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        Klasifikasi
                      </label>
                      <div class="col-md-8">
                        <treeselect v-model="klasifikasi" :options="options" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label
                        :class="[
                          'control-label',
                          { 'text-right': !isMobile },
                          'col-md-3',
                        ]"
                      >
                        Status
                      </label>
                      <div class="col-md-8">
                        <!-- <Input v-model="status" type="text" name="status" id="status" class="form-control" /> -->
                        <treeselect v-model="status" :options="optionsstatus" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="form-actions">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-offset-3 col-md-9">
                        <button
                          type="submit"
                          class="btn btn-success"
                          title="Search"
                        >
                          Submit</button
                        >&nbsp;
                        <button
                          type="button"
                          @click="resetInput()"
                          class="btn btn-inverse"
                          title="Reset"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row justify-content-md-center" v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>&nbsp;</b>
          <div class="pull-right">
            <!-- <downloadexcel
              class="btn btn-sm btn-success"
              :fetch="exportExcelBerkas"
              :fields="json_fields_berkas"
              worksheet="Daftar Berkas"
              name="DaftarBerkas.xls"
              title="Daftar Berkas"
              >Daftar Berkas</downloadexcel
            >&nbsp; &nbsp; -->
            <!-- <downloadexcel
              class="btn btn-sm btn-success"
              :fetch="exportExcelIsiBerkas"
              :fields="json_fields_isi_berkas"
              worksheet="Daftar Isi Berkas"
              name="DaftarIsiBerkas.xls"
              title="Daftar Isi Berkas"
              >Daftar Isi Berkas</downloadexcel
            >&nbsp; &nbsp; -->
            <!-- <downloadexcel
              class="btn btn-sm btn-success"
              :fetch="exportExcelInAktif"
              :fields="json_fields_inaktif"
              worksheet="Daftar Arsip Inaktif"
              name="DaftarArsipInaktif.xls"
              title="Daftar Arsip Inaktif"
              >Daftar Arsip Inaktif</downloadexcel
            >&nbsp; &nbsp; -->
            <!-- <downloadexcel
              class="btn btn-sm btn-success"
              :fetch="exportExcelAktif"
              :fields="json_fields_aktif"
              worksheet="Daftar Arsip Aktif"
              name="DaftarArsipAktif.xls"
              title="Daftar Arsip Aktif"
              >Daftar Arsip Aktif</downloadexcel
            >&nbsp;&nbsp; -->
            <button
              type="button"
              @click="goSchedule()"
              class="btn btn-sm btn-info"
              title="Update Data Arsip"
            >
              Update Data Arsip</button
            >&nbsp;&nbsp;
            <button
              @click="exportExcelDataBerkas('daftarberkas')"
              class="btn btn-sm btn-success"
              style="margin-right: 10px"
            >
              Daftar Berkas
            </button>&nbsp;&nbsp;
            <button
              @click="exportExcelDataAktif('daftararsipaktif')"
              class="btn btn-sm btn-success"
              style="margin-right: 10px"
            >
              Daftar Arsip Aktif
            </button>&nbsp;&nbsp;
            <button
              @click="exportExcelData('daftararsipinaktif')"
              class="btn btn-sm btn-success"
              style="margin-right: 10px"
            >
              Daftar Arsip Inaktif
            </button>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onEdit="doEdit"
              @onDelete="doDelete"
              :overideSearch="search"
              @onSearch="onSearch"
              :forbidEdit="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidPemberkasan="forbidPemberkasan"
              :forbidDelete="true"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="card card-body printableArea">
        <div class="col-md-12">
          <h4>Petunjuk :</h4>
        </div>
        <div class="col-md-12">
          <table>
            <tr>
              <td>
                <span class="btn-sm btn-success mr-1">
                  <i class="mdi mdi-magnify"></i>
                </span>
              </td>
              <td style="font-size: 13px">Untuk melihat data detail berkas</td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <td>
                <span class="btn-sm btn-warning mr-1"
                  ><i class="mdi mdi-pencil"></i
                ></span>
              </td>
              <td style="font-size: 13px">Untuk mengubah data berkas</td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import moment from "moment";
import { RotateSquare5 } from "vue-loading-spinner";
import Multiselect from "vue-multiselect";
import downloadexcel from "vue-json-excel";
import axios from "axios";

var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var htmlToPdfmake = require("html-to-pdfmake");

export default {
  components: {
    RotateSquare5,
    Table,
    Multiselect,
    // Treeselect,
    downloadexcel,
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");
    let params = {};
    if (last[0].includes("pemberkasan")) {
      params = JSON.parse(last[1]);
    }
    if (params.approval != undefined) {
      params = {};
    }
    return {
      search: params.search || "",
      start_date: moment().startOf("year").format("YYYY-MM-DD"),
      end_date: moment().endOf("year").format("YYYY-MM-DD"),
      tanggal:
        new Date().getDate() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear(),
      keterangan: "",
      penjelasan: "",
      klasifikasi: null,
      // namafolder: "",
      status: null,
      nomor: 1,
      payload: {
        klasifikasi: null,
        namafolder: "",
        status: null,
      },
      // tanggal : today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
      headers: {
        namafolder: {
          label: "Nama Indeks",
          sortable: true,
        },
        // klasifikasi: {
        //   label: "Klasifikasi Akses",
        //   sortable: true,
        // },
        // updated_at: {
        //   label: "Tanggal Diteruskan",
        //   sortable: true,
        // },
        // akhir_retensiaktif: {
        //   label: "Aktif",
        //   sortable: true,
        // },
        // akhir_retensiinaktif: {
        //   label: "Inaktif",
        //   sortable: true,
        // },
        retensi_aktif: {
          label: "Aktif",
          sortable: true,
        },
        retensi_inaktif: {
          label: "Inaktif",
          sortable: true,
        },
        informasi: {
          label: "Jumlah Surat",
          sortable: false,
        },
        // dstatus: {
        //   label: "Status Retensi",
        //   sortable: false,
        // },
      },
      json_fields: {
        "Nomor Urut": "urutan",
        "Nama Berkas": "namafolder",
        Klasifikasi: "klasifikasi",
        Keterangan: "description",
        "Tanggal Pembuatan": "updated_at",
        "Akhir Retensi Active": "akhir_retensiaktif",
        "Akhir Retensi Inactive": "akhir_retensiinaktif",
        "Surat Arsip": "informasi",
        "Status Retensi": "status",
      },
      json_fields_berkas: {
        "No. Berkas": "urut",
        "Kode Klasifikasi": "kode",
        "Uraian Informasi": "nama",
        Tahun: "tahun",
        Jumlah: "jumlah",
        "Kurun Waktu": "waktu",
        Keterangan: "keterangan",
      },
      json_fields_isi_berkas: {
        "No. Berkas": "urut",
        "Kode Klassifikasi": "klasifikasi",
        "Nama Berkas": "berkas",
        "Isi Uraian Informasi": "uraian",
        Jumlah: "jumlah",
        "Kurun Waktu": "waktu",
        "Tingkat Perkembangan": "perkembangan",
        "Lokasi Simpan": "lokasi",
        "Jangka Waktu Simpan dan Nasib Akhir": "jangka",
        "Klasifikasi Keamanan": "keamanan",
        "Hak Akses": "akses",
        "Dasar Pertimbangan": "pertimbangan",
      },
      json_fields_inaktif: {
        "No. Berkas": "urut",
        "Kode Klassifikasi": "klasifikasi",
        "Nama Berkas": "berkas",
        "Isi Uraian Informasi": "uraian",
        Jumlah: "jumlah",
        "Kurun Waktu": "waktu",
        "Tingkat Perkembangan": "perkembangan",
        "Lokasi Simpan": "lokasi",
        "Jangka Waktu Simpan dan Nasib Akhir": "jangka",
        "Klasifikasi Keamanan": "keamanan",
        "Hak Akses": "akses",
        "Dasar Pertimbangan": "pertimbangan",
      },
      json_fields_aktif: {
        "No. Berkas": "urut",
        "Kode Klasifikasi": "klasifikasi",
        "Nama Berkas": "berkas",
        "Isi Uraian Informasi": "uraian",
        Jumlah: "jumlah",
        "Kurun Waktu": "waktu",
        "Tingkat Perkembangan": "perkembangan",
        "Lokasi Simpan": "lokasi",
        "Jangka Waktu Simpan dan Nasib Akhir": "jangka",
        "Klasifikasi Keamanan": "keamanan",
        "Hak Akses": "akses",
        "Dasar Pertimbangan": "pertimbangan",
      },
      options: [],
      optionsstatus: [],
      isTouched: false,
      isLoading: false,
      isDisabled: false,
    };
  },
  computed: {
    indukklasifikasi() {
      return this.$store.state.pemberkasan.items
        ? this.$store.state.pemberkasan.items.treeklasifikasi
        : [];
    },
    items() {
      return this.$store.state.daftarfolder.items
        ? this.$store.state.daftarfolder.items.items
        : [];
    },
    prints() {
      return this.$store.state.daftarfolder.items
        ? this.$store.state.daftarfolder.items.prints
        : [];
    },
    filters() {
      return this.$store.state.daftarfolder.items
        ? this.$store.state.daftarfolder.items.filters
        : {};
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    state() {
      return this.$store.state.daftarfolder;
    },
    tanggal_diterima() {
      return formatDate(this.$store.state.daftarfolder.tanggal_diterima);
    },
    // perihal(){
    //   return this.$store.daftarfolder.subject;
    // },
    urutan() {
      return this.$store.state.daftarfolder.urutan;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.laporan_docin) {
        return !this.$store.state.profile.permissions.laporan_docin.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.laporan_docin) {
        return !this.$store.state.profile.permissions.laporan_docin.read;
      }
      return false;
    },
    forbidPemberkasan() {
      if (this.$store.state.profile.permissions.pemberkasan_berkas) {
        return this.$store.state.profile.permissions.pemberkasan_berkas.create;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.laporan_docin) {
        return !this.$store.state.profile.permissions.laporan_docin.delete;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      search: this.search,
      prints: [],
    };
    this.treeKlasifikasi();
    this.treeStatus();
    this.$store.commit("daftarfolder/STATE", state);
    this.get();
  },
  methods: {
    goSchedule(){
      this.$store.dispatch("daftarfolder/schedule");
    },
    exportExcelData(val) {
      const link = document.createElement("a");
      let unit_id = JSON.parse(localStorage.getItem("user")).unit_id;
      link.href = `${this.baseUrl}/${val}/export-excel?start_date=${this.start_date}&end_date=${this.end_date}&unit_id=${unit_id}`;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    exportExcelDataAktif(val) {
      const link = document.createElement("a");
      let unit_id = JSON.parse(localStorage.getItem("user")).unit_id;
      link.href = `${this.baseUrl}/${val}/export-excel?start_date=${this.start_date}&end_date=${this.end_date}&unit_id=${unit_id}`;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    exportExcelDataBerkas(val) {
      const link = document.createElement("a");
      let unit_id = JSON.parse(localStorage.getItem("user")).unit_id;
      // link.href = `${this.baseUrl}/${val}/export-excel?start_date=${this.start_date}&end_date=${this.end_date}`;
      link.href = `${this.baseUrl}/${val}/export-excel?start_date=${this.start_date}&end_date=${this.end_date}&unit_id=${unit_id}`;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    treeKlasifikasi() {
      axios.get(`/clasificationdoc/get_list_klasifikasi`).then((res) => {
        this.options = res.data.data;
      });
    },
    // treeKlasifikasi() {
    //   axios.get(`/mail_code/get_list`).then((res) => {
    //     this.options = res.data.data;
    //   });
    // },
    treeStatus() {
      this.optionsstatus = [
        { id: 1, label: "Active" },
        { id: 0, label: "Inactive" },
      ];
    },
    onSearch(val) {
      this.search = val;
    },
    // loadOptions({ callback }) {
    //     var vm = this;
    //     axios
    //     .get('/clasificationdoc/listtree')
    //     .then(function (response) {
    //         var options = parseOptions(response);
    //         vm.indukklasifikasi = options.items; // this is one critical step - make sure you update the options variable bound to your v-treeselect component
    //         callback(); // notify vue-treeselect about data population completion
    //     })
    //     .catch(function (error) {
    //         return 'error';
    //     });
    // },
    get(val) {
      this.$store.dispatch("daftarfolder/getdaftarfolder", val);
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    resetInput() {
      this.start_date = "";
      this.end_date = "";
      this.klasifikasi = "";
      this.namafolder = "";
      this.status = "";
      this.get(state);
    },
    pagination(params) {
      this.filterData(null, params);
    },
    filterData: function (event, params) {
      var val = {
        start_date: this.start_date,
        end_date: this.end_date,
        namafolder: this.payload.namafolder,
        klasifikasi: this.klasifikasi,
        status: this.status,
      };
      if (typeof params == "object" && params.constructor.name === "Object") {
        val = Object.assign(params, val);
      }
      this.get(val);
    },
    doDetail(val) {
      // this.$store.dispatch("daftarfolder/onDetail", val);
      const idsurat = val.id;
      let route = this.$router.resolve({ path: "/daftarfolder/detail" });
      let route1 = `${route.href}/${idsurat}`;
      window.open(route1, "_blank");
    },
    doEdit(val) {
      this.$store.dispatch("daftarfolder/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("daftarfolder/submitDelete", val);
    },

    // Start Export PDF
    onDownload() {
      var data = Array();
      var header = [
        { text: "No", style: "tableHeader" },
        { text: "Tanggal Pengiriman", style: "tableHeader" },
        { text: "Nomor Dokumen", style: "tableHeader" },
        { text: "Tanggal Dokumen", style: "tableHeader" },
        { text: "Sifat Surat", style: "tableHeader" },
        { text: "Perihal", style: "tableHeader" },
        { text: "Dari", style: "tableHeader" },
        { text: "Jenis Dokumen", style: "tableHeader" },
        { text: "Redaksi", style: "tableHeader" },
        { text: "Keterangan Surat", style: "tableHeader" },
        { text: "Hal", style: "tableHeader" },
        // { text: "Tujuan Disposisi", style: "tableHeader" },
        // { text: "Catatan Disposisi", style: "tableHeader" }
      ];
      data.push(header);
      var prints = this.prints;
      var result = Object.keys(prints).map(function (key) {
        return [Number(key), prints[key]];
      });
      var i = 1;
      result.forEach((element) => {
        data.push([
          i,
          element[1].tanggal_diterima,
          element[1].nomor_surat,
          element[1].tanggal_surat,
          element[1].sifat_surat,
          element[1].perihal,
          element[1].asal_surat,
          element[1].jenis_dokumen,
          element[1].redaksi,
          // htmlToPdfmake(element[1].keterangan_surat || "-"),
          element[1].keterangan_surat,
          element[1].halaman,
          // element[1].disposisi,
          // htmlToPdfmake(element[1].catdispo || "-")
        ]);
        i++;
      });
      var documentDefinition = {
        pageOrientation: "landscape",
        content: [
          {
            marginLeft: 50,
            marginRight: 50,
            width: [1000],
            table: {
              body: [
                [
                  {
                    text: "Kementerian Luar Negeri Republik Indonesia",
                    style: "header",
                    margin: [200, 10, 0, 0],
                  },
                ],
                [
                  {
                    text: "Laporan Surat Masuk",
                    style: "header",
                    margin: [200, 10, 0, 0],
                  },
                ],
              ],
            },
            layout: "headerLineOnly",
          },
          {
            marginTop: 10,
            table: {
              body: [
                [
                  {
                    text: "Tanggal",
                    width: 50,
                    marginRight: 50,
                    style: "tableHeader",
                  },
                  ":",
                  this.start_date + " s.d " + this.end_date,
                ],
              ],
            },
            layout: "noBorders",
            style: "tableHeader",
          },
          {
            marginTop: 15,
            table: {
              // headerRows: 1,
              body: data,
            },
            style: "tableHeader",
          },
          {
            marginTop: 10,
            text: "",
            style: "tableHeader",
          },
          {
            marginTop: 10,
            text: "",
            style: "tableHeader",
          },
          {
            text: "Jakarta, " + this.tanggal,
            style: "header2",
            marginLeft: 600,
          },
          {
            text: this.$store.state.daftarfolder.items.nama_satker,
            style: "header2",
            marginLeft: 600,
          },
        ],

        styles: {
          tableHeader: {
            bold: true,
            fontSize: 6,
            color: "black",
            alignment: "left",
          },
          tableHeader2: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
          },
          header: {
            bold: true,
            fontSize: 12,
            color: "black",
            alignment: "center",
          },
          header2: {
            fontSize: 10,
            color: "black",
            alignment: "center",
          },
        },
        pageMargins: [40, 60, 40, 60],
        pageSize: "A4",
      };
      pdfMake.createPdf(documentDefinition).print();
    },
    // End Export PDF //

    async exportExcel() {
      if (
        this.$store.state.daftarfolder.items.filters.start_date == null &&
        this.$store.state.daftarfolder.items.filters.end_date === null
      ) {
        const response = await axios.get("daftarfolder?search=&sortable=asc");
        return response.data.prints;
      } else {
        const response = await axios.get(
          "daftarfolder?start_date=" +
            this.start_date +
            "&end_date=" +
            this.end_date
        );
        return response.data.prints;
      }
    },
    async exportExcelBerkas() {
      if (
        this.$store.state.daftarfolder.items.filters.start_date == null &&
        this.$store.state.daftarfolder.items.filters.end_date === null
      ) {
        const response = await axios.get("daftarfolder?search=&sortable=asc");
        return response.data.prints;
      } else {
        const response = await axios.get(
          "daftarberkas/export-excel?start_date=" +
            this.start_date +
            "&end_date=" +
            this.end_date
        );
        return response.data.prints;
      }
    },
    async exportExcelIsiBerkas() {
      if (
        this.$store.state.daftarfolder.items.filters.start_date == null &&
        this.$store.state.daftarfolder.items.filters.end_date === null
      ) {
        const response = await axios.get("daftarfolder?search=&sortable=asc");
        return response.data.prints;
      } else {
        const response = await axios.get(
          "daftarisiberkas/export-excel?start_date=" +
            this.start_date +
            "&end_date=" +
            this.end_date
        );
        return response.data.prints;
      }
    },
    async exportExcelInAktif() {
      if (
        this.$store.state.daftarfolder.items.filters.start_date == null &&
        this.$store.state.daftarfolder.items.filters.end_date === null
      ) {
        const response = await axios.get("daftarfolder?search=&sortable=asc");
        return response.data.prints;
      } else {
        const response = await axios.get(
          "daftarfolderinaktif/export-excel?start_date=" +
            this.start_date +
            "&end_date=" +
            this.end_date
        );
        return response.data.prints;
      }
    },
    async exportExcelAktif() {
      if (
        this.$store.state.daftarfolder.items.filters.start_date == null &&
        this.$store.state.daftarfolder.items.filters.end_date === null
      ) {
        const response = await axios.get("daftarfolder?search=&sortable=asc");
        return response.data.prints;
      } else {
        const response = await axios.get(
          "daftarfolderaktif/export-excel?start_date=" +
            this.start_date +
            "&end_date=" +
            this.end_date
        );
        return response.data.prints;
      }
    },
  },
};
</script>
